import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    image: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { image } = props.data;

  return (
    <Layout departmentSlug="annals" title="Annals">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Annals</Core.DepartmentLabel>
        <Core.Flag>Pieces of History</Core.Flag>
        <Core.ContentSection>
          <br />
          <p>
            In 1997, German Foreign Minister Klaus Kinkel (1992–1998) dedicated
            a piece of the Berlin Wall to JHU SAIS. This gift from the Senate of
            Berlin was a tribute to the success of the German-American
            partnership—a symbol of the peaceful end of the Cold War. Only four
            years earlier, the Newseum in Washington, D.C., acquired eight
            12-foot-high segments of the Berlin Wall, along with the three-story
            East German guard tower that stood near Checkpoint Charlie, Berlin’s
            best-known east-west crossing. These elements formed the Berlin Wall
            exhibit, an immersive section of the museum designed to share how
            the news helped topple a closed society. Today, there are only 10
            locations in Washington, D.C., with pieces of the Berlin Wall. The
            school&rsquo;s piece of the wall (pictured here) sits in the
            courtyard in front of the Nitze Building: a formidable reminder of
            war, peace, policy, and partnership. Just across town, the Newseum’s
            pieces of the wall remind visitors of the struggles worldwide in the
            fight to preserve freedom of expression. Even though the Newseum
            will close its doors this year, its underlying mission matches well
            with that of the school&rsquo;s, reminding us that the past helps to
            shape the future world order, and that we’re all more alike than
            different.
          </p>
          <Core.FullWidthImage image={image} alt="" />
        </Core.ContentSection>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    image: file(
      relativePath: { regex: $directory }
      name: { eq: "berlin-wall" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
